<template>
  <div class="pay-invoice">
    <div class="payment-main-title">Payments</div>
    <!-- <PaymentCard></PaymentCard> -->
    <div class="pay-invoice-wrapper">
      <v-card flat class="ma-2 pay-invoice-content">
        <v-card-title class="heading font-weight-bold primary--text">
          Pay Invoice #INV-{{ invoice_id }}
        </v-card-title>
        <v-progress-linear v-if="loading" height="10" indeterminate />
        <v-card-text>
          <v-row dense class="fill-height" justify="center" align="stretch">
            <v-col :cols="mdAndUp ? 6 : 12">
              <v-card
                flat
                :loading="loading"
                v-if="invoice"
                class="mx-auto rounded-10"
                outlined
              >
                <v-card-title class="subtitle-2 primary--text">
                  {{ invoice.title | ucwords }}
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <div class="font-weight-bold">
                    Billed From :
                    {{ invoice.billable_from.fullname | ucwords }} <br />
                    Billed To : {{ invoice.billable_to.fullname | ucwords }}
                    <br />
                    Billed Date : {{ invoice.date }} <br />
                    Due Date : {{ invoice.due_date }}
                  </div>
                </v-card-text>
                <v-card-subtitle class="font-weight-bold">
Items:
</v-card-subtitle>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="4" class="font-weight-bold">Description</v-col>
                    <v-col cols="2" class="text-center font-weight-bold">
Price Per Unit
</v-col>
                    <v-col cols="2" class="text-center font-weight-bold">
Unit
</v-col>
                    <v-col cols="2" class="text-center font-weight-bold">
Quantity
</v-col>
                    <v-col cols="2" class="text-center font-weight-bold">
Amount
</v-col>
                  </v-row>
                  <v-divider class="mb-3" />
                  <v-row
                    dense
                    class="mb-3"
                    v-for="(item, index) in items"
                    :key="index"
                  >
                    <v-col cols="4">{{ item.description }}</v-col>
                    <v-col cols="2" class="text-center">
                      {{ item.price_per_unit }}
                    </v-col>
                    <v-col cols="2" class="text-center">
                      {{ item.units | ucwords }}
                    </v-col>
                    <v-col cols="2" class="text-center">
                      {{ item.quantity }}
                    </v-col>
                    <v-col cols="2" class="text-center">
                      {{ item.total | money }}
                    </v-col>
                  </v-row>
                  <v-row dense v-if="invoice.tax.show">
                    <v-col class="font-weight-bold">Tax</v-col>
                    <v-col :cols="mdAndUp ? 2 : 3" class="text-center">
                      {{ invoice.tax_value | money }}
                    </v-col>
                  </v-row>
                  <v-row dense v-if="invoice.discount.show">
                    <v-col class="font-weight-bold">Discount</v-col>
                    <v-col :cols="mdAndUp ? 2 : 3" class="text-center">
                      - {{ invoice.discount_value | money }}
                    </v-col>
                  </v-row>
                  <v-row dense v-if="invoice.shipping_fee.show">
                    <v-col class="font-weight-bold">Shipping</v-col>
                    <v-col :cols="mdAndUp ? 2 : 3" class="text-center">
                      {{ invoice.shipping_fee_value | money }}
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row dense class="mt-3">
                    <v-col class="font-weight-bold">Total Amount</v-col>
                    <v-col
                      :cols="mdAndUp ? 2 : 3"
                      class="font-weight-bold text-center"
                    >
                      {{ invoice.total | money }}
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text>
                  <v-row dense>
                    <v-col class="font-weight-bold" :cols="mdAndUp ? 2 : 12">
Notes :
</v-col>
                    <v-col :cols="mdAndUp ? 10 : 12">{{ invoice.notes }}</v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="font-weight-bold" :cols="mdAndUp ? 2 : 12">
Terms :
</v-col>
                    <v-col :cols="mdAndUp ? 10 : 12">{{ invoice.terms }}</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-alert type="error" outlined v-else-if="!loading">
                No Invoice found!
              </v-alert>
            </v-col>
            <v-col :cols="mdAndUp ? 6 : 12">
              <v-card
                flat
                outlined
                min-height="500"
                class="mx-auto pa-5"
                v-if="invoice && invoice.status === 'paid'"
              >
                <v-alert type="success" outlined>Invoice Paid!</v-alert>
              </v-card>
              <v-card
                flat
                outlined
                min-height="500"
                class="mx-auto pa-5"
                v-else-if="paymentIntent && can_pay_to_biller"
              >
                <v-card-title>
                  <h4 class="mb-5">Pay with card</h4>
                </v-card-title>
                <StripeElements
                  v-show="!loading"
                  ref="elementsRef"
                  :pk="publishableKey"
                  :amount="paymentIntent.amount"
                  @token="confirmCardPayment"
                  :stripe-account="stripeAccount"
                  @loading="loading = $event"
                />
                <v-btn
                  large
                  class="white--text"
                  color="primary"
                  :disabled="submitting"
                  block
                  @click="submit"
                  v-show="!loading"
                >
                  Pay ${{ paymentIntent.amount / 100 }}
                </v-btn>
                <v-card-actions class="mt-5">
                  <a
                    class="caption"
                    href="https://stripe.com/"
                    style="text-decoration: none; color: grey"
                    target="_blank"
                  >
                    Powered by <b>Stripe</b>
                  </a>
                </v-card-actions>
              </v-card>
              <v-card
                flat
                outlined
                min-height="500"
                class="mx-auto pa-5 rounded-10"
                v-else-if="!paymentIntent && !can_pay_to_biller"
              >
                <v-card-text>
                  <v-alert outlined type="error">
                    Cannot proceed to payment!
                  </v-alert>
                  <v-alert
                    v-if="!billedFromSelf && invoice"
                    outlined
                    type="info"
                  >
                    User <b>{{ invoice.billable_from.fullname }}</b>
                    cannot yet accept payment.
                  </v-alert>
                  <v-alert
                    v-if="billedFromSelf && invoice"
                    outlined
                    type="info"
                  >
                    <p>Connect your Stripe Account to accept payment.</p>
                    <v-btn text :href="stripeConnect" outlined class="mx-auto">
                      Connect with Stripe
                    </v-btn>
                  </v-alert>
                </v-card-text>
              </v-card>
              <v-card
                flat
                outlined
                min-height="500"
                class="mx-auto pa-5 rounded-10"
                v-else-if="!paymentIntent && billedToSelf"
              >
                <v-btn text :href="stripeConnect" outlined class="mx-auto">
                  Connect with Stripe
                </v-btn>
              </v-card>
              <v-alert type="error" outlined v-else-if="!loading">
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import request from "@/services/axios_instance";
//components
import { StripeElements } from "vue-stripe-checkout";
import { mapGetters } from "vuex";
import PaymentCard from "./PaymentCard/PaymentCard.vue";

export default {
  name: "PayInvoice",
  components: {
    StripeElements,
    PaymentCard,
  },
  data: () => ({
    loading: false,
    stripeAccount: null,
    token: null,
    charge: null,
    invoice_id: null,
    invoice: null,
    paymentIntent: null,
    errorMessage: null,
    submitting: false,
    parseTemplate: "",
  }),
  watch: {
    "$route.params": {
      handler: function (val) {
        this.invoice_id = val.hasOwnProperty("id") ? Number(val.id) : null;
        if (this.invoice_id) {
          this.getPaymentIntent(this.invoice_id);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    publishableKey() {
      return this.settings.stripe.pub_key;
    },
    items() {
      return this.invoice ? this.invoice.items : [];
    },
    billedToSelf() {
      return this.invoice && this.invoice.billable_to.id === this.user.id;
    },
    stripeConnect() {
      return `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${this.settings.stripe.client_id}&scope=read_write&redirect_uri=${this.settings.stripe.redirect_uri}`;
    },
    can_pay_to_biller() {
      return this.invoice && this.invoice.billable_from.stripe_id;
    },
    billedFromSelf() {
      return this.invoice && this.invoice.billable_from.id === this.user.id;
    },
    style() {
      return `min-height:470px; width: 100%;border:none;`;
    },
  },
  methods: {
    getPaymentIntent(id) {
      this.loading = true;
      request
        .get(`api/stripe/payment-intent/${id}`)
        .then(({ data }) => {
          if (data.message) {
            this.errorMessage = data.message;
          } else {
            this.paymentIntent = data.payment_intent;
            this.stripeAccount = data.stripe_account;
          }
          if (data.invoice) {
            this.invoice = data.invoice;
          }
        })
        .finally(() => (this.loading = false));
    },
    submit() {
      this.$refs.elementsRef.submit();
    },
    connectToStripe() {
      this.$router.push({ name: "connect" });
    },
    confirmCardPayment() {
      let card = this.$refs.elementsRef.card;
      this.$refs.elementsRef.stripe
        .confirmCardPayment(`${this.paymentIntent.client_secret}`, {
          payment_method: {
            card: card,
            billing_details: {
              name: this.user.fullname,
            },
          },
        })
        .then((result) => {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            this.appSnackbar(result.error.message, "error");
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === "succeeded") {
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions. @kirby: webhook added on BE to update the invoice status
              this.invoice.status = "paid";
              this.appSnackbar("Successfully paid!");
            }
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    getParseHtml(invoice_id) {
      request
        .get(`api/invoice/${invoice_id}/parse-template`)
        .then(({ data }) => {
          this.parseTemplate = data.html;
        });
    },
    resizeIframe(obj) {
      obj.style.height =
        obj.contentWindow.document.documentElement.scrollHeight + "px";
    },
  },
};
</script>

<style lang="scss" scoped>
.pay-invoice {
  background: white;
  border-radius: 10px;
  padding: 10px;
  .pay-invoice-wrapper {
    margin: 10px;
    background-color: $white;
    border: 1px solid $tableBorderBlue;
    border-radius: 10px;
    padding: 10px;

    .pay-invoice-content {
      background-color: $tableBlueBg;
      border: 1px solid $tableBorderBlue;
      padding: 10px;
    }
  }
  .payment-main-title {
    font-size: 25px;
    color: #223a6b;
    font-weight: 500;
  }
}
</style>